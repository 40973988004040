import React from "react"

import notFoundImage from "../images/404-image.png"
import { Helmet } from "react-helmet"

const NotFoundPage = () => (
  <>
    <Helmet>
      <title>Not Found | Fraser Community Child Care Centre & Out of School Care</title>
      <link rel="canonical" href="https://frasercommunitychildcarecenter.com/404" />
    </Helmet>
    <section className="section not-found__section">
      <div className="not-found__content">
        <img className="not-found__image" src={notFoundImage} alt="Not Found Icecream" />
        <h1 className="heading heading--title not-found__404">404</h1>
        <h3 className="heading not-found__subtitle">Oops! That page can&rsquo;t be found.</h3>
      </div>
	  </section>
  </>
)

export default NotFoundPage
